<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey678', '订单信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey561', '订单编号')+':'"
                                label-width="8em"
                            >
                                {{ order.orderSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey268', '商品类型')+':'"
                                label-width="8em"
                            >
                                {{ order.goodsSendModeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey571', '下单时间')+':'"
                                label-width="8em"
                            >
                                {{ order.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey595', '订单金额')+':'"
                                label-width="8em"
                            >
                                {{ order.payableAmount }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey695', '配送时间')+':'"
                                label-width="8em"
                            >
                                {{ order.shippingTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey696', '收货时间')+':'"
                                label-width="8em"
                            >
                                {{ order.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey116', '收货人姓名')+':'"
                                label-width="8em"
                            >
                                {{ order.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey117', '收货人电话')+':'"
                                label-width="8em"
                            >
                                {{ order.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey502', '收货人地址')+':'"
                                label-width="8em"
                            >
                                {{ order.deliveryRegionName }} {{ order.deliveryAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey711', '买家昵称')+':'"
                                label-width="8em"
                            >
                                {{ order.nickName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey596', '订单状态')+':'"
                                label-width="8em"
                            >
                                {{ order.orderStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('pageKey507', '买家留言')+':'"
                                label-width="8em"
                            >
                                {{ order.buyerMsg }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey619', '下单人')+':'"
                                label-width="8em"
                            >
                                +{{ order.buyerCountryCode }}-{{ order.buyerMobile }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="order.isDistributor"
                        >
                            <el-form-item
                                :label="$t('pageKey750', '代理商')+':'"
                                label-width="8em"
                            >
                                {{ order.distributorName }} <span class="tc-primary">+{{ order.distributorCountryCode }}-{{ order.distributorTel }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col
                            v-if="order.storeName"
                            :span="12"
                        >
                            <el-form-item
                                :label="$t('pageKey620', '门店')+':'"
                                label-width="8em"
                            >
                                {{ order.storeName }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey680', '付款信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey681', '付款模式')+':'"
                                label-width="8em"
                            >
                                {{ order.paymentModeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey682', '付款时间')+':'"
                                label-width="8em"
                            >
                                {{ order.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey568', '付款类型')+':'"
                                label-width="8em"
                            >
                                {{ order.paymentTypeName }}<span v-if="order.paymentComments">/{{ order.paymentComments }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey751', '付款单号')+':'"
                                label-width="8em"
                            >
                                {{ order.externalPaymentSn }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey416', '商品信息') }}</span>
                    </div>
                    <div class="item">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.orderDetailList"
                            style="width: 100%"
                        >
                            <el-table-column
                                :label="$t('pageKey215', '商品图片')"
                                width="120"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        :data="scope.row.specImageUrl"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="goodsName"
                                :label="$t('pageKey204', '商品名称')"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                :label="$t('pageKey254', '商品规格')"
                                min-width="100"
                            />
                            <el-table-column
                                prop="finalPrice"
                                :label="$t('pageKey243', '商品价格')"
                                min-width="50"
                            />
                            <el-table-column
                                prop="quantity"
                                :label="$t('pageKey292', '数量')"
                                min-width="50"
                            />
                            <el-table-column
                                prop="subtotalAmount"
                                :label="$t('pageKey685', '小计')"
                                min-width="50"
                            />
                        </el-table>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey752', '售后信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey753', '售后编号')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey754', '售后时间')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey742', '售后类型')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.orderDetailId ? $t('pageKey744', '商品售后') : $t('pageKey743', '订单取消') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey755', '退款类型')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.orderRefundTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey756', '售后原因')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundReasonName }}
                            </el-form-item>
                        </el-col>
                        <template v-if="order.paymentMethod === 1">
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('pageKey757', '售后商品数量')+':'"
                                    label-width="8em"
                                >
                                    {{ orderRefund.refundQuantity }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('pageKey748', '退款金额')+':'"
                                    label-width="8em"
                                >
                                    {{ orderRefund.refundAmount }}
                                </el-form-item>
                            </el-col>
                        </template>
                        <template v-if="order.paymentMethod === 2">
                            <el-col :span="12">
                                <el-form-item
                                    :label="$t('pageKey758', '退款积分')+':'"
                                    label-width="8em"
                                >
                                    {{ orderRefund.refundPointNum }}
                                </el-form-item>
                            </el-col>
                        </template>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey759', '售后状态')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('pageKey760', '售后说明')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundDesc }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('pageKey761', '售后凭证')+':'"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="orderRefund.refundAttachmentUrlList || ''"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderRefund.refundStatus !== 1 && orderRefund.refundStatus !== 5"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey762', '审核信息') }}</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            :label="$t('pageKey763', '审核操作人')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.auditFullName }}({{ orderRefund.auditUserName }})
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey616', '审核时间')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.auditTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey764', '审核说明')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.auditDesc }}
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey765', '审核凭证')+':'"
                            label-width="8em"
                        >
                            <ImageList
                                :data="orderRefund.auditAttachmentUrlList || ''"
                            />
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="orderRefund.orderRefundType == 2 && (orderRefund.refundStatus == 2 || orderRefund.refundStatus == 3 || orderRefund.refundStatus == 7)"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey766', '退货信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey114', '退货地址')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundRegionName }} {{ orderRefund.refundAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey767', '退货收件人')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey768', '退货电话')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundMobile }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey598', '物流公司')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.expressName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey573', '快递单号')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.trackingNumber }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey769', '退货时间')+':'"
                                label-width="8em"
                            >
                                {{ orderRefund.refundTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderRefund.orderRefundType == 2 && (orderRefund.refundStatus == 2 || orderRefund.refundStatus == 3)"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey770', '收货信息') }}</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            :label="$t('pageKey771', '收货操作人')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.recFullName }}({{ orderRefund.recUserName }})
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey696', '收货时间')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderRefund.refundStatus == 3 && order.paymentMode == 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey680', '付款信息') }}</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            :label="$t('pageKey772', '付款操作人')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.payFullName }}({{ orderRefund.payUserName }})
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey682', '付款时间')+':'"
                            label-width="8em"
                        >
                            {{ orderRefund.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey491', '付款凭证')"
                            label-width="8em"
                        >
                            <ImageList
                                :data="orderRefund.paymentAttachmentUrlList || ''"
                            />
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderRefund.refundStatus == 1 && (!order.isAgentGoods || userData.appId === 9)"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey762', '审核信息') }}</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            prop="refundAmount"
                            :label="$t('pageKey748', '退款金额')"
                            label-width="8em"
                            v-if="order.paymentMethod === 1"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="formModel.refundAmount"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="auditAttachment"
                            :label="$t('pageKey765', '审核凭证')"
                            label-width="8em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.auditAttachment"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="auditDesc"
                            :label="$t('pageKey773', '审核备注')"
                            label-width="8em"
                            v-if="orderRefund.refundStatus == 1"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.auditDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirmPass()"
                            >
                                {{ $t('pageKey643', '审核通过') }}
                            </el-button>
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirmErr()"
                            >
                                {{ $t('pageKey556', '审核不通过') }}
                            </el-button>
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderRefund.refundStatus == 2 && order.paymentMode == 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey680', '付款信息') }}</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            prop="paymentAttachment"
                            :label="$t('pageKey491', '付款凭证')"
                            label-width="8em"
                        >
                            <upload
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                :limit="1"
                                v-model="formModel.paymentAttachment"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="paymentDesc"
                            :label="$t('pageKey774', '付款备注')"
                            label-width="8em"
                            v-if="orderRefund.refundStatus == 2 && order.paymentMode == 2"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.paymentDesc"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onPayConfirm()"
                            >
                                {{ $t('pageKey775', '提交付款信息') }}
                            </el-button>
                        </el-form-item>
                    </div>
                </el-card>
                <el-col :span="12">
                    <el-form-item
                        label-width="8em"
                        class="ma-t"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onRecConfirm()"
                            v-if="orderRefund.refundStatus == 7"
                        >
                            {{ $t('pageKey776', '确认收货') }}
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            {{ $t('pageKey126', '返 回') }}
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrderRefundDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                auditStatus: '',
                auditAttachment: [],
                auditDesc: '',
                paymentAttachment: [],
                paymentDesc: '',
                refundAmount: undefined,
            },
            // 表单校验规则
            formRules: {
                auditDesc: {
                    required: true,
                    message: this.$t('pageKey777', '请输入审核备注'),
                    trigger: 'blur',
                },
                paymentDesc: {
                    required: true,
                    message: this.$t('pageKey778', '请输入付款备注'),
                    trigger: 'blur',
                },
                refundAmount: {
                    required: true,
                    message: this.$t('pageKey779', '请输入退款金额'),
                    trigger: 'blur',
                },
            },
            orderRefund: {},
            order: {},
        };
    },
    methods: {
        init() {
            this.$api.Ex.OrderRefund.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.orderRefund = res.orderRefund || {};
                this.order = res.order || {};
                // this.formModel.refundAmount = res.orderRefund.refundAmount;
            });
        },
        onConfirmPass() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm(this.$t('pageKey780', '是否确定审核通过！'), this.$t('pageKey42', '温馨提示!'), {
                    type: 'warning',
                    confirmButtonText: this.$t('pageKey19', '确定'),
                    cancelButtonText: this.$t('pageKey20', '取消'),
                }).then(() => {
                    this.formModel.auditStatus = 1;
                    this.$api.Ex.OrderRefund.orderRefundAudit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onConfirmErr() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm(this.$t('pageKey781', '是否确定审核不通过！'), this.$t('pageKey42', '温馨提示!'), {
                    type: 'warning',
                    confirmButtonText: this.$t('pageKey19', '确定'),
                    cancelButtonText: this.$t('pageKey20', '取消'),
                }).then(() => {
                    this.formModel.auditStatus = 2;
                    this.$api.Ex.OrderRefund.orderRefundAudit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onPayConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm(this.$t('pageKey782', '是否确定提交付款信息！'), this.$t('pageKey42', '温馨提示!'), {
                    type: 'warning',
                    confirmButtonText: this.$t('pageKey19', '确定'),
                    cancelButtonText: this.$t('pageKey20', '取消'),
                }).then(() => {
                    this.$api.Ex.OrderRefund.orderRefundPay({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onRecConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm(this.$t('pageKey783', '是否确定收货！'), this.$t('pageKey42', '温馨提示!'), {
                    type: 'warning',
                    confirmButtonText: this.$t('pageKey19', '确定'),
                    cancelButtonText: this.$t('pageKey20', '取消'),
                }).then(() => {
                    this.$api.Ex.OrderRefund.orderRefundRec({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
