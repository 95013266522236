<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.refundStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    :label="$t('pageKey555', '待审核')"
                    name="1"
                />
                <el-tab-pane
                    :label="$t('pageKey737', '待打款')"
                    name="2"
                />
                <el-tab-pane
                    :label="$t('pageKey738', '已退款')"
                    name="3"
                />
                <el-tab-pane
                    :label="$t('pageKey739', '已关闭')"
                    name="4"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="refundSn"
                    :label="$t('pageKey740', '退款单号')"
                >
                    <el-input
                        v-model="queryFormModel.refundSn"
                        :placeholder="$t('pageKey741', '请输入退款单号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderSn"
                    :label="$t('pageKey561', '订单编号')"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        :placeholder="$t('pageKey562', '请输入订单编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="refundType"
                    :label="$t('pageKey742', '售后类型')"
                >
                    <el-select
                        v-model="queryFormModel.refundType"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            key="1"
                            :label="$t('pageKey743', '订单取消')"
                            value="1"
                        />
                        <el-option
                            key="2"
                            :label="$t('pageKey744', '商品售后')"
                            value="2"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="insertTime"
                    :label="$t('pageKey745', '申请时间')"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.insertTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :range-separator="$t('pageKey475', '至')"
                        :start-placeholder="$t('pageKey474', '开始日期')"
                        :end-placeholder="$t('pageKey476', '结束日期')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column type="expand">
                    <template slot-scope="order">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                        >
                            <el-table-column
                                :label="$t('pageKey215', '商品图片')"
                                width="120"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        :data="scope.row.specImageUrl"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="goodsName"
                                :label="$t('pageKey204', '商品名称')"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                :label="$t('pageKey254', '商品规格')"
                                min-width="100"
                            />
                            <el-table-column
                                prop="finalPrice"
                                :label="$t('pageKey243', '商品价格')"
                                min-width="50"
                            />
                            <el-table-column
                                prop="quantity"
                                :label="$t('pageKey292', '数量')"
                                min-width="50"
                            />
                            <el-table-column
                                prop="subtotalAmount"
                                :label="$t('pageKey685', '小计')"
                                min-width="50"
                            />
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="refundSn"
                    :label="$t('pageKey740', '退款单号')"
                    min-width="100"
                />
                <el-table-column
                    prop="orderSn"
                    :label="$t('pageKey561', '订单编号')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSendModeName"
                    :label="$t('pageKey268', '商品类型')"
                    min-width="100"
                />
                <el-table-column
                    prop="orderStatusName"
                    :label="$t('pageKey596', '订单状态')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey742', '售后类型')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderDetailId ? $t('pageKey744', '商品售后') : $t('pageKey743', '订单取消') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="refundReasonName"
                    :label="$t('pageKey746', '退款原因')"
                    min-width="100"
                />
                <el-table-column
                    prop="refundQuantity"
                    :label="$t('pageKey747', '退款商品数量')"
                    min-width="100"
                />
                <el-table-column
                    prop="refundAmount"
                    :label="$t('pageKey748', '退款金额')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey745', '申请时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="70"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/order/afterSale/orderRefund/detail/' + scope.row.id)"
                        >
                            {{ scope.row.refundStatus === 1 ? $t('pageKey611', '审核') : scope.row.refundStatus === 2 && scope.row.paymentMode === 2 ? $t('pageKey749', '线下付款') : $t('pageKey603', '查看') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'OrderRefund',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                refundSn: '', // 退款单号
                orderSn: '', // 订单编号
                orderRefundType: '1',
                refundStatus: '1',
                refundType: '',
                goodsName: '',
                insertTime: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.OrderRefund.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
